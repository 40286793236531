<div class="wrapper">
	<!-- <button (click)="stockManager()">Stock manager</button> -->
	<!-- <button (click)="openDialog()">open modal</button> -->
	<app-manager-header
		[venue]=" venue$  | async"
		[showFaq]="true"
		[showChip]="true"
	></app-manager-header>

	<app-table-list-draw></app-table-list-draw>

	<!-- <app-external-order-provider-status></app-external-order-provider-status> -->
	<div class="order-container" #scrollableContent>
		@for (order of (orders$ | async); track order?._id) {

		<app-manager-order-item-draw
			[order]="order"
			(sendOrderStatusChange)="updateStatus($event)"
		>
		</app-manager-order-item-draw>

		}
	</div>
	<div class="nav-buttons">
		<button mat-icon-button class="scroll-btn left" (click)="scrollLeft()">
			<mat-icon>chevron_left</mat-icon>
		</button>
		<button mat-icon-button class="scroll-btn right" (click)="scrollRight()">
			<mat-icon>chevron_right</mat-icon>
		</button>
	</div>
</div>
