import {
	ChangeDetectionStrategy,
	Component,
	OnDestroy,
	OnInit
} from '@angular/core';
import {
	BehaviorSubject,
	Subject,
	catchError,
	delay,
	map,
	of,
	takeUntil,
	tap
} from 'rxjs';
import {Venue} from 'src/app/models/Venue';
import {ApiService} from 'src/app/services/api.service';
import {
	NAVIGATIONURLS,
	NavigationService
} from 'src/app/services/navigation.service';
import {ManagerFacade} from 'src/app/store/manager/+state/manager.facade';
import {
	DeliveryProvider,
	PrepTimeInputDialogComponent
} from '../prep-time-input-dialog/prep-time-input-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {AskPermissionDialogComponent} from '../ask-permission-dialog/ask-permission-dialog.component';
import {AuthFacade} from 'src/app/store/auth-state/+state/auth.facade';
import {TranslateService} from '@ngx-translate/core';
import {PreorderType} from 'src/app/models/PreorderType';
import {SmoothrInputOffsetsDialogComponent} from '../smoothr-input-offsets-dialog/smoothr-input-offsets-dialog.component';
import Offset from 'src/app/models/Offset';
import {TimeUtils} from '../../utils/time-utils';

// import Offset from 'src/app/models/Offset';

@Component({
	selector: 'app-admin-console-options',
	templateUrl: './admin-console-options.component.html',
	styleUrl: './admin-console-options.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminConsoleOptionsComponent implements OnInit, OnDestroy {
	public isWoltActiveInVenue$ = new BehaviorSubject<boolean>(false);
	public isUberEatsActiveInVenue$ = new BehaviorSubject<boolean>(false);

	public urlLinkEnum = NAVIGATIONURLS;
	public statusUber$ = this.managerFacade.statusUber$;
	public statusWolt$ = this.managerFacade.statusWolt$;
	public venue$ = this.managerFacade.venue$;
	public venueValue$ = new BehaviorSubject<Venue | null>(null);
	public deliveryProvider = DeliveryProvider;
	private readonly onDestroy$ = new Subject<void>();
	public preorderTypeEnum = PreorderType;
	public isVenueOpen$ = new BehaviorSubject<boolean>(false);

	constructor(
		private managerFacade: ManagerFacade,
		private authFacade: AuthFacade,
		private apiService: ApiService,
		private dialog: MatDialog,
		private navService: NavigationService,
		private translate: TranslateService
	) {}

	ngOnInit() {
		this.venue$.pipe(takeUntil(this.onDestroy$)).subscribe(venue => {
			if (venue) {
				this.venueValue$.next(venue);
				this.isVenueOpen$.next(!!this.isVenueOpen(venue));
			}
			this.venueValue$.next(venue);

			if (venue?.externalOrderProviders) {
				// const { uberEats, wolt } = venue.externalOrderProviders;
				if (venue?._id) {
					this.managerFacade.statusUber(venue._id);
				}
				if (venue?._id) {
					this.managerFacade.statusWolt(venue._id);
				}
			}
		});

		this.statusWolt$
			.pipe(
				tap(value => {
					this.isWoltActiveInVenue$.next(value?.isActive ?? false);
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.statusUber$
			.pipe(
				tap(value => {
					this.isUberEatsActiveInVenue$.next(value?.isActive ?? false);
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
	}

	updateUber() {
		const venue = this.venueValue$.getValue();
		if (venue) {
			const valueOfActive = !this.isUberEatsActiveInVenue$.getValue()
				? this.translate.instant('admin_console.ask_permissions_eats_online')
				: this.translate.instant('admin_console.ask_permissions_eats_offline');
			const dialogRef = this.dialog.open(AskPermissionDialogComponent, {
				data: {
					text: valueOfActive
				}
			});

			dialogRef.afterClosed().subscribe(result => {
				if (result) {
					this.deactivateActivateServiceUber(
						venue?._id,
						!this.isUberEatsActiveInVenue$.getValue()
					)
						.pipe(
							delay(500),

							tap(value => {
								this.managerFacade.statusUber(venue._id);
							}),
							takeUntil(this.onDestroy$)
						)
						.subscribe();
				}
			});
		}
	}

	updateWolt() {
		const venue = this.venueValue$.getValue();
		if (venue) {
			const valueOfActive = !this.isWoltActiveInVenue$.getValue()
				? this.translate.instant('admin_console.ask_permissions_wolt_online')
				: this.translate.instant('admin_console.ask_permissions_wolt_offline');
			const dialogRef = this.dialog.open(AskPermissionDialogComponent, {
				data: {
					text: valueOfActive
				}
			});
			dialogRef.afterClosed().subscribe(result => {
				if (result) {
					this.deactivateActivateServiceWolt(
						venue?._id,
						!this.isWoltActiveInVenue$.getValue()
					)
						.pipe(
							delay(500),
							tap(value => {
								this.managerFacade.statusWolt(venue._id);
							}),
							takeUntil(this.onDestroy$)
						)
						.subscribe();
				}
			});
		}
	}

	private deactivateActivateServiceUber(venueId: string, status: boolean) {
		return this.apiService.activateDeactivateActivateUber(venueId, status).pipe(
			map(response => {
				console.log(response);
				if (response.status === 200) {
					return {status: response.status};
				} else {
					throw new Error(`Unexpected status: ${response.status}`);
				}
			}),
			catchError(error => {
				return of({status: error.status || 500});
			})
		);
	}

	private deactivateActivateServiceWolt(venueId: string, status: boolean) {
		return this.apiService.activateDeactivateActivateWolt(venueId, status).pipe(
			map(response => {
				if (response.status === 200) {
					return {status: response.status};
				} else {
					throw new Error(`Unexpected status: ${response.status}`);
				}
			}),
			catchError(error => {
				return of({status: error.status || 500});
			})
		);
	}

	async openDialogSetTime(type: DeliveryProvider) {
		const venue = this.venueValue$.getValue();
		if (!venue) {
			return;
		}
		const dialogRef = this.dialog.open(PrepTimeInputDialogComponent, {
			data: {
				type: type,
				value: venue.externalOrderProviders,
				venueId: venue._id
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log(result);
			// if (venue._id) {
			// 	this.managerFacade.loadVenue({ venueId: venue._id });
			// }
		});
	}

	updateSmoothrWithParams(venue: Venue, preorderType: PreorderType) {
		if (!venue) {
			return;
		}
		const copyVenue = JSON.parse(JSON.stringify(venue)) as Venue;
		if (preorderType === PreorderType.DELIVERY) {
			copyVenue.deliveryEnabled = !copyVenue.deliveryEnabled;
		} else if (preorderType == PreorderType.TAKE_AWAY) {
			copyVenue.preorderTakeAway = !copyVenue.preorderTakeAway;
		} else {
			return;
		}
		let text = '';
		if (preorderType === PreorderType.DELIVERY) {
			text = copyVenue.deliveryEnabled
				? this.translate.instant(
						'admin_console.ask_permissions_self_delivery_online'
				  )
				: this.translate.instant(
						'admin_console.ask_permissions_self_delivery_offline'
				  );
		} else if (preorderType === PreorderType.TAKE_AWAY) {
			text = copyVenue.preorderTakeAway
				? this.translate.instant(
						'admin_console.ask_permissions_self_take_away_online'
				  )
				: this.translate.instant(
						'admin_console.ask_permissions_self_take_away_offline'
				  );
		}

		const dialogRef = this.dialog.open(AskPermissionDialogComponent, {
			data: {
				text: text
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.apiService
					.updateVenueDelivery(copyVenue)
					.pipe(takeUntil(this.onDestroy$))
					.subscribe(result => {
						if (result && result?._id) {
							this.venueValue$.next(result);
						}
						console.log('VEnue Result', result);
					});
			}
		});
	}

	logout() {
		const dialogRef = this.dialog.open(AskPermissionDialogComponent, {
			data: {
				text: this.translate.instant('admin_console.logout_question')
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.authFacade.logout();
				this.navService.login();
			}
		});
	}

	public updateSmoothrOffsetsType() {
		const copyVenue = JSON.parse(
			JSON.stringify(this.venueValue$.getValue())
		) as Venue;
		if (!copyVenue) {
			return;
		}
		const dialogRef = this.dialog.open(SmoothrInputOffsetsDialogComponent, {
			data: {
				venue: copyVenue
			}
		});
		dialogRef
			.afterClosed()
			.subscribe(
				(result: {deliveryOffset: Offset; take_awayOffset: Offset}) => {
					if (result) {
						this.updateOffsetWithQuery(copyVenue, result);
					}
				}
			);
	}

	private updateOffsetWithQuery(
		venue: Venue,
		result: {
			deliveryOffset: Offset;
			take_awayOffset: Offset;
		}
	) {
		if (!venue) {
			return;
		}
		venue.offsets.preorder.takeAway = result.take_awayOffset;
		venue.offsets.preorder.delivery = result.deliveryOffset;

		this.apiService
			.updateVenueDelivery(venue)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(result => {
				if (result && result?._id) {
					this.venueValue$.next(result);
				}
				console.log('VEnue Result', result);
			});
	}

	isVenueOpen = (venue: Venue) => {
		return (
			venue &&
			venue.isServiceActivated &&
			venue.openingHours &&
			venue.openingHours?.length > 0 &&
			TimeUtils.doesHoursMatchNow(venue.openingHours)
		);
	};
}
